<template>
  <Container> 
    <router-view/>
  </Container> 

</template>

<script>

import Container from './components/Container.vue';

export default {
  name: 'App',
  components: {
    Container,
  }
};
</script>

<style>

html {
  height: 100%;
}

#app {
  position: relative;
  overflow-y: hidden;
  height: 100%;
  z-index: -2;
}

body {
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: -3;
}

/* 对全局的card定义的样式，这是p-card-content 的父亲 */ 
.p-card-body{
  position: relative;
  
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* 全局的card样式，它是p-card-body的孩子，所有在card的元素都作为该节点的孩子 */
.p-card-content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* 对全局card定义的样式，所有我们创建的内容都会被加入到这个标签的孩子中。 */
.container > .p-card-body > .p-card-content {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

</style>
