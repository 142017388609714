import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// ui库
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';

import 'primevue/resources/themes/lara-light-green/theme.css';
import 'katex/dist/katex.min.css';

const app = createApp(App);
app.use(store).use(router).mount('#app'); 
app.use(PrimeVue, {unstyled: false, ripple: true});
app.use(ConfirmationService);

// 程序启动，先发一个ping包试试，如果没有，直接404
import axios from 'axios';

// eslint-disable-next-line no-unused-vars
axios.get('https://rcodeanalysis.cn/api/ping/').then((res) => {
    res;
}).catch((err) => {
    router.push('/404');
    err
});
