<template>
  <Card class="display-container">
    <template #content>
      <div class="display" ref="displayREF">
        <div v-for="(message, index) in history_messages" :key="message.timestamp" class="message-per">
            <img v-if="message.role === 'assistant'" src="https://rcodeanalysis.cn/s/static/image/logo.png" class="message-avatar">
            <img v-else src="https://rcodeanalysis.cn/s/static/image/userdefalutlogo.png" class="message-avatar">
            <div v-if="message.role === 'assistant'" class="message-name">Analysis</div>
            <div v-else class="message-name">You</div>
            <template v-if="message.user_uploads && message.user_uploads.length > 0">
              <div class="FileDisplayPanel">
                <template v-for="(upload, index) in message.user_uploads" :key="index">
                  <div v-if="isImageType(upload.URL)" class="ImageParentElement">
                    <img :src="decodeURIComponent(upload.URL)" :alt="upload.file_name" class="message-image">
                  </div>
                  <div v-else class="DataParentElement">
                    <div class="DataTypeInfo">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none" class="icon-svg" width="36" height="36"><rect width="36" height="36" rx="6" fill="#0000FF"></rect><path d="M18.833 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V15.5L18.833 9.66663Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18.833 9.66663V15.5H24.6663" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                      <div class="DataTypeLabel">{{ upload.file_name }}</div>
                      <div class="DataRevealButton" @click="toggleDetails(upload)">
                        {{ upload.detailsShown ? 'cleardetails' : '↓details' }}
                      </div>
                    </div>
                    <transition name="fade">
                      <div v-if="upload.detailsShown" class="DataTypeContent">
                        <pre class="DataTypeContent-content">{{ upload.details }}</pre>
                      </div>
                    </transition>
                  </div>
                </template>
              </div>
            </template>
            <div class="marked-html message-text" v-html="renderedMessages[index]"></div>
        </div>
        <div class="message-per" v-for="(message, index) in dymaticMessages" :key="index">
          <img v-if="message.role === 'user'" src="https://rcodeanalysis.cn/s/static/image/userdefalutlogo.png" class="message-avatar">
          <img v-else src="https://rcodeanalysis.cn/s/static/image/logo.png" class="message-avatar">
          <div v-if="message.role === 'user'" class="message-name">You</div>
          <div v-else class="message-name">Analysis</div>
          <template v-if="message.user_uploads && message.user_uploads.length > 0">
            <div class="FileDisplayPanel">
              <template v-for="(upload, index) in message.user_uploads" :key="index">
                <div v-if="isImageType(upload.URL)" class="ImageParentElement">
                  <img :src="decodeURIComponent(upload.URL)" :alt="upload.file_name" class="message-image">
                </div>
                <div v-else class="DataParentElement">
                  <div class="DataTypeInfo">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none" class="icon-svg" width="36" height="36"><rect width="36" height="36" rx="6" fill="#0000FF"></rect><path d="M18.833 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V15.5L18.833 9.66663Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18.833 9.66663V15.5H24.6663" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    <div class="DataTypeLabel">{{ upload.file_name }}</div>
                    <div class="DataRevealButton" @click="toggleDetails(upload)">
                      {{ upload.detailsShown ? 'cleardetails' : '↓details' }}
                    </div>
                  </div>
                  <transition name="fade">
                    <div v-if="upload.detailsShown" class="DataTypeContent">
                      <pre class="DataTypeContent-content">{{ upload.details }}</pre>
                    </div>
                  </transition>
                </div>
              </template>
            </div>    
          </template>
          <div class="marked-html message-text" v-html="renderedContent(message.content)"></div>
        </div>

        <div v-if="loading" class="loading-indicator-tips">{{ loadingText }}
          <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
        </div>     
        <div v-else-if="callCatalyst" class="loading-calling-function">
          <ProgressSpinner style="width: 25px; height: 25px" strokeWidth="8" fill="var(--surface-ground)"
            animationDuration=".5s" aria-label="Custom ProgressSpinner" />  
          {{ loadingText }}
        </div>

        <!-- 这里是显示重新请求的提示按钮 -->
        <div v-if="!isRetryShutDown && history_messages[history_messages.length-1] && history_messages[history_messages.length-1].role === 'user'" class="message-per">
          <img src="https://rcodeanalysis.cn/s/static/image/logo.png" class="message-avatar">
          <div class="message-name">Analysis</div>
          <div class="message-text-error">[Response Error].发生了些错误，可点击下方按钮Regenerate重新请求。</div>
          <button class="retry-button" @click="retryPost">
            <div class="retry-button-text">Regenerate</div>
          </button>
        </div>  
      </div>
      <MessageComponent class="MessageComponent" v-for="message, index in primevueERRORMessages" :key="index" :messageInfo="message" :index="index"></MessageComponent>
      
      <Dialogue ref="dialogueREF"
                @to_display_dynamic_messages="to_display_dynamic_messages" 
                @showLoadingIndicator="showLoadingIndicator" @hideLoadingIndicator="hideLoadingIndicator" @showToolIndicator="showToolIndicator" @hideToolIndicator="hideToolIndicator" @showUploadProgress="showUploadProgress" @hideUploadProgress="hideUploadProgress"
                @pushInfoMessage="pushInfoMessage" @get_session_list="get_session_list"
                @showVoiceDialogue="showVoiceDialogue"
      ></Dialogue>
    </template>
  </Card>


  <Card class="voiceDialogueContainer" v-if="isShowVoiceDialogue">
    <template #content>
      <div class="voiceDialogue">
        <button class="startRecordingBTN" @click="startRecording">开始说话</button>
        <button class="stopRecordingBTN" @click="stopRecording">我说完了</button>
      </div>
    </template>
  </Card>


</template>

<script>
import { useStore } from 'vuex';
import { marked } from 'marked';
import { watch, ref, onMounted, onUnmounted, reactive } from 'vue';
import hljs from 'highlight.js';
import 'highlight.js/styles/foundation.css';
import 'katex/dist/katex.min.css';
import axios from 'axios';
import MessageComponent from './MessageComponent.vue';

import Card from 'primevue/card';
import Dialogue from '@/components/DialogueClient.vue'; 
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';

const render = new marked.Renderer();
render.code = (code, language) => {
  const validLanguage = hljs.getLanguage(language) ? language : 'plaintext';
  const highlighted = hljs.highlight(validLanguage, code).value;

  const base64Code = btoa(unescape(encodeURIComponent(code)));

  // 构建 HTML 结构
  return `
    <div class="message-code">
      <div class="message-code-name">${validLanguage}
        <div class='message-code-copy' data-clipboard-text='${base64Code}'>Copy code</div>
      </div>
      <pre class="message-code-text"><code class="language-${validLanguage}">${highlighted}</code></pre>
    </div>
  `;
};
render.image = (href, title, text) => {
  return `<img class="message-image" src="${decodeURIComponent(href)}" alt="${text}">`;
};

const options = {
  renderer: render, // 这是必填项
  gfm: true,	// 启动类似于Github样式的Markdown语法
  pedantic: false, // 只解析符合Markdwon定义的，不修正Markdown的错误
  sanitize: false, // 原始输出，忽略HTML标签（关闭后，可直接渲染HTML标签）
  throwOnError: false, // markdown语法错误是否抛出异常

	// 高亮的语法规范
  highlight: function(code, lang) {
    if (lang && hljs.getLanguage(lang)) {
      return hljs.highlight(lang, code).value;
    }
    return hljs.highlightAuto(code).value;
  },
}
marked.use(options);

export default {
  props: {
    history_messages: Array,
  },
  name: 'DisplayArea',
  setup(props, { emit }) {
    const store = useStore();
    const renderedMessages = ref([]);
    const dymaticMessages = ref([]);
    const primevueERRORMessages = ref([]);
    const scrollposition = reactive({});  // 这里记录的是每一个session-id对应的滚动条的位置。
    const iscurscrollAtBottom = ref(false);
    const loading = ref(false);
    const callCatalyst = ref(false);
    const loadingText = ref('');
    const timeouts = ref([]);
    const isRetryShutDown = ref(false);
    const isShowVoiceDialogue = ref(false);
    
    // div ref：
    const displayREF = ref(null); 
    const dialogueREF = ref(null);

    // config
    const tools_map = {
      "openai_standard_image_generate_from_dalle3": "Analyzing DALL-E3",
      "code_interpreter_assistant_handler": "Analyzing Code-Interpreter",
      "google_search": "Visiting Google",
      "visit_specific_website": "Visiting Website",
      "get_FileContent_from_URL" : "Reading Files",
      "imageVision": "Reading Image"
    };

    const renderedContent = (content) => {
      // 首先使用 marked 将 Markdown 转换为 HTML
      let htmlContent = marked(content);

      // 定义数学表达式的 delimiters
      // const delimiters = [
      //   { left: "\\$\\$", right: "\\$\\$", display: true },
      //   { left: "\\$", right: "\\$", display: false },
      //   { left: "\\(", right: "\\)", display: false },
      //   { left: "\\[", right: "\\]", display: true },
      // ];

      // // 对每种 delimiter 应用正则替换
      // delimiters.forEach(delimiter => {
      //   const regex = new RegExp(`${delimiter.left}(.*?)${delimiter.right}`, 'g');
      //   htmlContent = htmlContent.replace(regex, (match, formula) => {
      //     try {
      //       const renderedFormula = katex.renderToString(formula, { throwOnError: false, displayMode: delimiter.display });
      //       return renderedFormula;
      //     } catch (e) {
      //       console.error("KaTeX rendering error:", e); // 打印任何渲染错误
      //       return formula;
      //     }
      //   });
      // });
      return htmlContent;
    };

    watch(() => props.history_messages, () => {
      if (props.history_messages == undefined) {
        return;
      }

      if (props.history_messages == []) {
        // 这是一个清空历史消息的信号
        renderedMessages.value = [];
        return;
      }

      props.history_messages.forEach((message, index) => {
        if (message == '') {
          return;
        }
        renderedMessages.value[index] = renderedContent(message.content);

        // 判断当前滚动条数组中是否记录了该session-判断当前滚动条数组中是否记录了该session-id的滚动条位置，如果没有，则滚动到最底部
        const cur_session_id = store.state.user.cur_session_id;
        if (scrollposition[cur_session_id] === undefined) {
          setTimeout(() => {
            displayREF.value.scrollTop = displayREF.value.scrollHeight;
            scrollposition[cur_session_id] = displayREF.value.scrollHeight;
          }, 0);
        } else {
          // 如果记录了该session-id的滚动条位置，那么就滚动到该位置
          setTimeout(() => {
            displayREF.value.scrollTop = scrollposition[cur_session_id];
          }, 0);
        }
      });
    }, { immediate: true });

    const to_display_dynamic_messages = (role, text, uploads) => {
      if (!text || text == '') return;
      if (role == 'Analysis') {
        // 如果dymaticMessages最后一个元素的角色是user，那么push一个空内容到dymaticMessages中
        if (dymaticMessages.value.length > 0 && dymaticMessages.value[dymaticMessages.value.length - 1].role === 'user') {
          dymaticMessages.value.push({content: '', role: role});
        }

        // 如果不是END，那么就将消息加入到dymaticMessages.value的最后一个元素中
        if (text !== 'END' && dymaticMessages.value.length > 0) {
          let updatedMessages = [...dymaticMessages.value];
          updatedMessages[updatedMessages.length - 1].content += text;
          dymaticMessages.value = updatedMessages;
        }
      } else {
        if (dymaticMessages.value.length === 0) {
          // 这是一个新的对话
          iscurscrollAtBottom.value = true;
        }
        dymaticMessages.value.push({content: text, role: role, user_uploads: uploads});
      }

      if (role === 'user' || iscurscrollAtBottom.value) {
        setTimeout(() => {
          displayREF.value.scrollTop = displayREF.value.scrollHeight;
        }, 0);
      }
    }    

    const clear_dymatic_messages = () => {
      dymaticMessages.value = [];
    }

    const showLoadingIndicator = () => {
      loading.value = true;
      loadingText.value = '加载中...';

      // 清除上一次的所有setTimeout
      timeouts.value.forEach(clearTimeout);
      timeouts.value = [];

      timeouts.value.push(setTimeout(() => {
        if (loading.value) {
          loadingText.value = '加载中... 请耐心等待~';
        }
      }, 3000));

      timeouts.value.push(setTimeout(() => {
        if (loading.value) {
          loadingText.value = '加载中... 很快就好啦~';
        }
      }, 6000));

      timeouts.value.push(setTimeout(() => {
        if (loading.value) {
          loadingText.value = '大A竟然也有不争气的时候嘛，让我们再给它些时间吧！';
        }
      }, 12000));

      if (iscurscrollAtBottom.value) {
        setTimeout(() => {
          displayREF.value.scrollTop = displayREF.value.scrollHeight;
        }, 0);
      }
    };

    const hideLoadingIndicator = () => {
      // 当loading变为false时，清除所有setTimeout并重置loadingText
      loading.value = false;
      timeouts.value.forEach(clearTimeout);
      timeouts.value = [];
      loadingText.value = ''; // 或者其他什么默认文字
    };

    const showToolIndicator = (function_name) => {
      if (tools_map[function_name] === undefined) {
        console.error("No such tool in showToolIndicator: ", function_name);
        // alert("No such tool in showToolIndicator: " + function_name);
        return;
      }
      hideLoadingIndicator();
      callCatalyst.value = true;
      loadingText.value =  tools_map[function_name] + ' ...'

      if (iscurscrollAtBottom.value) {
        setTimeout(() => {
          displayREF.value.scrollTop = displayREF.value.scrollHeight;
        }, 0);
      }
    };

    const hideToolIndicator = () => {
      callCatalyst.value = false;
      loadingText.value = '';
    };

    const showUploadProgress = () => {
      emit('showUploadProgress')
    };

    const hideUploadProgress = () => {
      emit('hideUploadProgress')
    };

    const pushInfoMessage = (content) => {
      primevueERRORMessages.value.push(content);
      // 倒计时3秒删除该被加入的元素
      setTimeout(() => {
        primevueERRORMessages.value.shift();
      }, 3000);
    };

    // // 监听copy点击事件
    // const clickHandler = (event) => {
    //   if (event.target.classList.contains('message-code-copy')) {
    //     // const textToCopy = event.target.getAttribute('data-clipboard-text');
    //     const base64Code = event.target.getAttribute('data-clipboard-text');
    //     // 解码 Base64 字符串
    //     const decodedCode = decodeURIComponent(escape(atob(base64Code)));
    //     navigator.clipboard.writeText(decodedCode).then(() => {
    //       event.target.textContent = 'Copied';
    //       setTimeout(() => {
    //         event.target.textContent = 'Copy code';
    //       }, 3000);
    //     }).catch(err => {
    //       console.error('Error in copying text: ', err);
    //     });
    //   }
    // }

    const clickHandler = (event) => {
      if (event.target.classList.contains('message-code-copy')) {
        const base64Code = event.target.getAttribute('data-clipboard-text');
        const decodedCode = decodeURIComponent(escape(atob(base64Code)));

        if (navigator.clipboard && navigator.clipboard.writeText) {
          // 新版API
          navigator.clipboard.writeText(decodedCode).then(() => {
            event.target.textContent = 'Copied';
            setTimeout(() => {
              event.target.textContent = 'Copy code';
            }, 3000);
          }).catch(err => {
            console.error('Error in copying text: ', err);
            alert('Error in copying text: ' + err);
          });
        } else {
          // 回退到旧的复制方式
          const textArea = document.createElement('textarea');
          textArea.value = decodedCode;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          
          try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
            event.target.textContent = 'Copied';
            setTimeout(() => {
              event.target.textContent = 'Copy code';
            }, 3000);
          } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            alert('Fallback: Oops, unable to copy: ' + err);
          }
          
          document.body.removeChild(textArea);
        }
      }
    }

    // 监听滚轮事件
    const wheelHandler = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
        // 查询所有相关类的元素
        const elements = displayREF.value.querySelectorAll('.message-name, .message-text, .message-code-name, .message-code-text');
        elements.forEach(el => {
          // 获取当前字体大小
          let currentFontSize = parseInt(window.getComputedStyle(el).fontSize);
          let newFontSize;
          // 根据滚轮方向调整字体大小
          if (event.deltaY < 0) {
            // 向上滚动 - 增大字体大小
            newFontSize = currentFontSize + 1;
          } else {
            // 向下滚动 - 减小字体大小
            newFontSize = currentFontSize - 1;
          }
          // 检查新字体大小是否在允许的范围内
          if (newFontSize >= 10 && newFontSize <= 22) {
            el.style.fontSize = newFontSize + 'px';
          }
        });

        // 查询所有图片类的元素
        const avatarElements = displayREF.value.querySelectorAll('.message-avatar');
        avatarElements.forEach(el => {
          // 获取并调整图片尺寸
          let currentWidth = parseInt(window.getComputedStyle(el).width);
          let newWidth;
          if (event.deltaY < 0) {
            newWidth = currentWidth + 2; // 增大尺寸
          } else {
            newWidth = currentWidth - 2; // 减小尺寸
          }
          el.style.width = newWidth + 'px';
          el.style.height = newWidth + 'px'; // 保持宽高比
        });
      }
    }

    // 监听display滚动事件
    const scrollHandler = (event) => {
      if (props.history_messages.length === 0 && dymaticMessages.value.length === 0) {
        return;
      }

      const cur_session_id = store.state.user.cur_session_id;
      const scrollTop = event.target.scrollTop;
      scrollposition[cur_session_id] = scrollTop;

      // 判断当前滚动条位置是否在底部，注意误差值
      if (scrollTop + event.target.clientHeight >= event.target.scrollHeight - 5) {
        iscurscrollAtBottom.value = true;
      } else {
        iscurscrollAtBottom.value = false;
      }
    }

    const showVoiceDialogue = () => {
      isShowVoiceDialogue.value = true;
    }

    const hideVoiceDialogue = () => {
      isShowVoiceDialogue.value = false;
    }

    onMounted(() => {
      if (displayREF.value) {
        displayREF.value.addEventListener('click', clickHandler);
        displayREF.value.addEventListener('wheel', wheelHandler);
        displayREF.value.addEventListener('scroll', scrollHandler);
      }
    })

    // 不要忘记在组件卸载时移除事件监听器
    onUnmounted(() => {
      if (displayREF.value) {
        displayREF.value.removeEventListener('click', clickHandler);
        displayREF.value.removeEventListener('wheel', wheelHandler);
        displayREF.value.removeEventListener('scroll', scrollHandler);
      }
    });

    const get_session_list = () => {
      emit('get_session_list');
    }

    const isImageType = (url) => {
      return /\.(jpg|jpeg|png|gif)$/i.test(url);
    }

    const toggleDetails = (upload) => {
      if (!upload.detailsShown) {
        // 如果没有显示，那么就显示，通过upload.URL获取文件内容，可以使用axios.get方法，请求的url为upload.URL
        try {
          upload.details = 'Loading...';
          const decodeURL = decodeURIComponent(upload.URL);
          console.log("upload.URL = ", decodeURL);
          axios.get(decodeURL, {
            responseType: 'blob', // 用于文件下载
          }).then((resp) => {
            const blob = resp.data;
            const reader = new FileReader();
            reader.onload = () => {
              // 当读取完成后，result 包含 Blob 数据的文本
              upload.details = reader.result;
            };
            reader.readAsText(blob); // 读取 Blob 作为文本
          });
        } catch (e) {
          upload.details = 'Error loading file';
        }
      }
      // 切换显示状态
      upload.detailsShown = !upload.detailsShown;
    }

    const retryPost = () => {
      if (dialogueREF.value) {
        isRetryShutDown.value = true;
        dialogueREF.value.retryPost();
      }
    }

    const startRecording = () => {
      if (dialogueREF.value) {
        dialogueREF.value.startRecording();
      }
    }

    const stopRecording = () => {
      if (dialogueREF.value) {
        dialogueREF.value.stopRecording();
      }
    }
    
    return {
      store,
      renderedMessages,dymaticMessages,primevueERRORMessages,
      loading,loadingText,callCatalyst,
      displayREF,dialogueREF,
      scrollposition,
      renderedContent,
      to_display_dynamic_messages,
      showLoadingIndicator,hideLoadingIndicator,showToolIndicator,hideToolIndicator,showUploadProgress,hideUploadProgress,
      pushInfoMessage,
      clear_dymatic_messages,
      get_session_list,
      isImageType,toggleDetails,
      retryPost,isRetryShutDown,
      showVoiceDialogue,hideVoiceDialogue,startRecording,stopRecording,isShowVoiceDialogue,
    }
  },
  components: {
    Card,
    Dialogue,
    ProgressBar,ProgressSpinner,
    MessageComponent,
  },
}


</script>

<style scoped>

.display-container {
  background-color: transparent;  
  height: 100%;
  width: 100%;
  flex: 1;
}

.display {
  width: 85%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;
  align-self: center;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  padding: 1em;
}

/* 加载提示的样式和动画 */
.loading-indicator-tips {
    display: inline-block;
    padding: 10px;
    color: #999;
    font-style: italic;
    animation: blink 1s linear infinite;
}

.loading-calling-function {
    display: inline-block;
    padding: 10px 15px;
    color: #E53935;
    font-style: italic;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}

.FileDisplayPanel {
  position: relative;
  top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 2em;
}

.DataParentElement {
  position: relative;
  left: 45px;
  width: 45%;
  border-radius: 10px;
  margin-right: 10px;
  min-height: 60px;
  height: auto;
}

.DataTypeInfo {
  background-color: darkgray;
  width: 100%;
  height: 60px;
  margin: 0;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-family: monospace;
  color: #f8f8f2;
}

.DataTypeLabel {
  display: inline-block;
  position: absolute;
  left: 65px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.DataRevealButton {
position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  font-weight: 900;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  margin: 5px 0;
  transition: background-color 0.3s;
}


.DataTypeContent {
  max-height: 500px;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  overflow-x: auto;
  overflow-y: auto;
  background-color: #fff;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
  top: 60px;
  position: relative;
  opacity: 1;
  padding: 15px;
  margin-bottom: 4em;
  border: 1px solid #ddd;
}

.DataTypeContent-content {
  white-space: pre-wrap;
}

.icon-svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

.ImageParentElement[data-v-47b39aef] {
    position: relative;
    margin: 0;
    display: inline-table;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.MessageComponent {
  z-index: 100000;
}
.voiceDialogueContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* background-color: transparent; */
  /* 超级浅的灰色背景 */
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: absolute;
}

.voiceDialogue {
    position: absolute;
    z-index: 10000;
    height: 85%;
    width: 93%;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(73, 73, 73);
    border: none;
    /* 白色亮光 */
    filter: drop-shadow(0 0 0.75rem #fff);

    display: flex;
    /* 让孩子垂直居中，水平居中且间距相等 */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 10px;
    border-radius: 10px;
}

.startRecordingBTN {
  position: relative;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.startRecordingBTN:hover {
  background-color: #45a049;
}

.stopRecordingBTN {
  position: relative;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.stopRecordingBTN:hover {
  background-color: #d32f2f;
}


</style>

<style>

.message-infomation {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    padding: 0.5em;
    /* border-radius: 5px; */
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    position: relative;
}

.message-per {
    margin-bottom: 2.5em;
    padding: 0.5em;
    /* border-radius: 5px; */
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    position: relative;
}

.message-avatar {
    width: 40px;
    height: 40px;
    max-height: 40px;
    max-width: 40px;
    min-height: 18px;
    min-width: 18px;

    border-radius: 50%;
    margin-right: 10px;

    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    user-select: none;
}

.message-name {
    position: absolute;
    left: 50px;
    top: 12px;
    font-size: 18px;
    font-weight: bold;
    user-select: none;
}

.message-text {
    color: black;
    position: relative;
    top: 30px;
    left: 45px;
    width: 95%;

    font-size: 18px;
    line-height: 1.75;
    letter-spacing: 0.2px;
    margin: 0;
}

.message-text-error {
    color: red;
    font-weight: bold;
    position: relative;
    top: 30px;
    left: 45px;
    width: 95%;

    font-size: 18px;
    line-height: 1.75;
}

.retry-button {
    position: relative;
    align-items: center;
    border-color: transparent;
    border-radius: 0.5rem;
    border-width: 1px;
    display: inline-block;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.25rem;
    padding: 0.5rem, 0.75rem;
    pointer-events: auto;
    background-color: #10a37f;

    bottom: -40px;
    left: 45%;
    cursor: pointer;
    user-select: none;
}

.retry-button:hover {
    background-color: #0c7a5f;
}


.retry-button-text {
    color: white;
    margin: 10px;
    font-weight: bold;
}

.message-image {
    position: relative;
    left: 50px;
    top: 0;
    max-width: 70%;
    max-height: 70%;
    margin-bottom: 20px;
    border-radius: 5px;
    object-fit: contain;
    user-select: none;
}

.message-code {
    position: relative;
    width: 90%;
    margin-bottom: 2em;
    margin-left: 1em;
    background-color: black;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: #f8f8f2;
    font-family: Fira Code, Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;
    overflow: auto;
    will-change: scroll-position;
    font-size: 16px;
}

.message-code::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
}

.message-code-name {
    /* position: relative; */
    position: sticky;
    font-size: 14px;
    font-weight: 800;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: white;
    /* left: -12px; */
    /* top: -15px; */

    top: -20px;
    left: -12px;
    padding: 0;
    margin-left: -20px;
    margin-top: -15px;
    margin-bottom: 10px;

    width: 100%;
    background-color: #333;
}

.message-code-copy {
    position: absolute;
    width: 40%;
    right: -30px;
    top: 0%;
    height: 100%;
    background: #333;
    color: #fff;
    border: none;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    text-align: end;
    padding-right: 10px;
}

.message-code-text {  
    font-size: 18px;
    color: white;
    position: relative;
    /* margin-bottom: 1em; */
    margin: 0;
    word-spacing: 2px;
}


pre.message-code-text {
    display: inline-block;
    overflow-x: auto;
    background-color: #000;
    padding: 0;
}

pre code.hljs {
    display: inline-block;
    /* white-space: pre-wrap; */
    padding: 0;
    font-family: monospace;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.8;
}

code {
    font-family: 'Consolas', 'Courier New', Courier, Monaco, monospace;
}


p {
  margin: 0;
  margin-bottom: 1em;
  display: block;
}

.display-container > .p-card-body > .p-card-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 95%;
  bottom: 0;
}


::-webkit-scrollbar {
    width: 10px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
}

</style>