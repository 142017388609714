<template>
    <Card class="session-bar">
        <template #content>
            <div class="card create_newsession_btn_card">
                <button class="create_newsession_button" @click="create_newsession">
                    <img class="logo" src="https://rcodeanalysis.cn/s/static/image/logo.png" style="height: 52px; width: 52px; border-radius: 50%;">
                    创建新的对话
                </button>
            </div>
            <div class="card">
                <ScrollPanel class="scroll-panel"
                :pt="{
                    wrapper: {
                        style: 'border-right: 10px solid var(--surface-50); border-bottom: 10px solid var(--surface-50);'
                    },
                    bary: 'surface-300 opacity-100 border-noround'
                }"
                >

                    <li v-for="session in sessions" :key="session.id"
                        class="session-per"
                        :class="{ active: activeSessionId === session.id }"
                        v-on:click="activeSessionId === session.id ? null : getConversation($event, session.id)"
                        >
                        <div class="session-per-name">{{ session.name }}</div>

                        <ConfirmPopup></ConfirmPopup>
                        <Button class="session-bar-danger-btn" @click="deleteSessionBtn($event, session)" icon="pi pi-times" label="X" severity="danger"></Button>      
                    </li>


                </ScrollPanel>
            </div>
        </template>
    </Card>
</template>

<script>
import Card from 'primevue/card';
import ScrollPanel from 'primevue/scrollpanel';
import ConfirmPopup from 'primevue/confirmpopup';
import Button from 'primevue/button';
import { useConfirm } from "primevue/useconfirm";


import $ from 'jquery';
import { useStore } from 'vuex';
import router from '@/router';
import cookies from 'js-cookie';
import { ref, watch } from 'vue';

export default {
    name: 'session-bar',
    props: {
        isPhone: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const activeSessionId = ref(null);
        const sessions = ref([]);
        const store = useStore();
        const isVisible = ref(false);
        const isPhone = ref(false);

        // ui 
        const confirm = useConfirm(); 
        // end

        // 监听isPhone:
        watch(() => props.isPhone, () => {
            isPhone.value = props.isPhone;
        });

        
        const getSessions = () => {
            const username = cookies.get('username');
            if (username !== undefined) {
                store.commit('updateUserLoginState', {username});
            }

            if (store.state.user.is_login === false) {
                router.push({name: 'login'});
                return;
            }

            $.ajax({
                url: 'https://rcodeanalysis.cn/api/get_session_list/',
                type: "GET",
                data: {
                    username: store.state.user.username,
                },
                success: function (resp) {
                    if (Object.prototype.hasOwnProperty.call(resp, 'sessions')) {
                        sessions.value = [...resp.sessions];
                    }
                }.bind(this),
                error: function(resp){
                    console.log(resp);
                }.bind(this),
            })
        }

        const getConversation = ($event, session_id) => {
            if ($event.target !== $event.currentTarget) {
                return;
            }

            // 清空历史消息
            emit('to_display_history_messages', []);
            emit('clear_dymatic_messages');

            $.ajax({
                url: 'https://rcodeanalysis.cn/api/get_history_from_sessionid/',
                type: "GET",
                data: {
                    username: store.state.user.username,
                    session_id: session_id,
                },
                success: function (resp) {
                    if (Object.prototype.hasOwnProperty.call(resp, 'messages')) {
                        store.commit('updateSelectSessionId', {session_id: session_id});
                        activeSessionId.value = session_id;
                        emit('to_display_history_messages', resp.messages);
                        return;
                    }
                }.bind(this),
                error: function(resp){
                    console.log(resp);
                }.bind(this),
            })
        }

        const create_newsession = () => {
            activeSessionId.value = null;

            // 清空历史消息
            emit('to_display_history_messages', []);
            emit('clear_dymatic_messages');
            store.commit('updateSelectSessionId', {session_id: -1});
        }

        const deleteSession = (session_id) => {
            $.ajax({
                url: 'https://rcodeanalysis.cn/api/deleteSession/',
                type: "POST",
                data: {
                    session_id: session_id,
                },
                success: function (resp) {
                    if (Object.prototype.hasOwnProperty.call(resp, 'success')) {
                        store.commit('updateSelectSessionId', {session_id: -1});
                        getSessions();
                        emit('to_display_history_messages', []);
                        emit('clear_dymatic_messages');
                        activeSessionId.value = null;
                        return;
                    }
                }.bind(this),
                error: function(resp){
                    console.log(resp);
                }.bind(this),
            })
        }

        const deleteSessionBtn = (event, session) => {
            confirm.require({
                target: event.currentTarget,
                message: '您想要删除这条记录吗？您无法撤消此操作。',
                acceptClass: 'p-button-danger',
                acceptLabel: 'Yes',
                position: 'right',    
                onShow: () => {
                    isVisible.value = true;
                },
                onHide: () => {
                    isVisible.value = false;
                },
                accept: () => {
                    deleteSession(session.id);   
                }
            });
        }


        return {
            sessions,confirm,
            activeSessionId,
            getSessions,deleteSession,getConversation,create_newsession,
            deleteSessionBtn,

        }
    },
    components: {
        Card,
        ScrollPanel,
        ConfirmPopup,
        Button,
    }, 
    mounted() {
        this.getSessions();
    },
    methods: {
    },
};

</script>

<style scoped>

.session-bar {
    background-color: white;        
    height: 100%;
    width: 230px;

    /* flex:  0 0 15%; */
    z-index: 1000;  
}

.scroll-panel{
    position: relative;
    height: 80vh;
    width: 95%;
    padding: 0;
    left: 0;
    user-select: none;
    overflow-y: auto;
    overflow-x: hidden;
}

/* ##########################  在同一个card【div】中  ############################# */
.create_newsession_btn_card{
    margin-bottom: 20px;
    height: 60px;
    width: 100%;
}

.create_newsession_button {
  display: flex;
  padding: 0.5rem 1.4rem;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  gap: 0.75rem;
  color: rgb(65, 63, 63);
  background-color: #fff;
  cursor: pointer;
  transition: all .6s ease;

  position: relative;
  top: 0;
  height: 100%;
  width: 210px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
}

.create_newsession_button:hover {
    /* 变颜色的速度快一些 */
    background-color: #ebebeb;
}

/* ##########################  END  ############################# */


.session-per {
    width: 100%;
    height: 50px;
    background-color: #f5f5f5;
    margin-bottom: 10px;  
    padding: 0;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    color: black;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
}

.session-per .session-per-name {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
    padding: 0;
    pointer-events: none;
}

.session-per:hover {
    background-color: #ebebeb;
}

.session-per.active {
    background-color: #d9d9d9;
}

.create_newsession_btn_card {
    user-select: none;
}

/* 默认隐藏删除按钮 */
.session-per .session-bar-danger-btn {
    display: none;
}

/* 当鼠标悬停在 session-per 上时，显示删除按钮 */
.session-per:hover .session-bar-danger-btn {
    display: block;
}

</style>

<style>

/* 滚动列表+滚动条这个整体的相对位置 */
.p-scrollpanel {
    position: relative;
    margin-left: 10px;
    border: 0;
    overflow-x: hidden;
}

/* 这个是session-panel滚动条中的每一个元素父类的样式 */
.p-scrollpanel-content{
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: hidden;
}

/* 这个是session-panel滚动条中每一个孩子（内容）的样式 */
.p-scrollpanel-content > * {
  flex: 0 0 auto;
  margin: 10px 0px;
  justify-content: center; 
  align-items: center;
}

.scroll-panel-content{
    height: 100vh;
}

.session-bar-danger-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 25px;
    right: 0;
}

.session-bar-danger-btn > .p-button-label {   
    position: relative;
    left: -4px;
}

</style>