import $ from 'jquery';
import cookies from 'js-cookie';
import router from '@/router';

const ModuleUser = {
  state: {
    username: "",
    photo: "",
    is_login: false,
    cur_session_id : -1,
    project_cur_session_id : -1,
    isWebSocketConnecting: false,

  },
  getters: {
  },
  mutations: {
    updateUserLoginState(state, data) {
        // 目前当登录成功之后，data只会存在一个success的标志，后面可以添加用户的一些信息，比如头像的链接地址
        state.is_login = true;
        state.username = data.username;
        cookies.set('username', data.username, { expires: 7, path: '/' })
    },
    updateSelectSessionId(state, data) {
        state.cur_session_id = data.session_id;
    },
    updateSelectProjectSessionId(state, data) {
        state.project_cur_session_id = data.project_session_id;
    },
    updateWebSocketConnectingState(state, data) {
        state.isWebSocketConnecting = data.isWebSocketConnecting;
    },
    
  },
  actions: {
      login(context, data) {
        // 获得username和password的string值
        const username = data.username;
        const password = data.password;

        // 判断username的长度是否大于4小于20，密码长度是否大于6小于20
        if (username.length < 4 || username.length > 20) {
            alert("用户名长度不符合要求");
            return;
        }
        if (password.length < 6 || password.length > 20) {
            alert("密码长度不符合要求");
            return;
        }

        $.ajax({
            url: "https://rcodeanalysis.cn/api/login/loginPost",
            type: "POST",
            data: {
                accountText: username,
                passwdText: password,
            },
            dataType: "json",
            success: function (resp) {
                console.log(resp.status);
                if (resp.status === 'success') {
                    context.commit('updateUserLoginState', {username});

                    router.push({name: 'home'});
                } else if (resp.state === 'infoErr') {
                    console.log("账号或密码错误");
                    alert("账号或密码错误");
                }
            },
            error() {
                alert(data.error());
            }
        })
      },
  },
  modules: {
  }
};

export default ModuleUser;