<template>
    <Card class="container">
        <template #content>
            <slot/>
        </template>
    </Card>
</template>

<script>
import Card from 'primevue/card';

export default {
    name: 'defalut-container',
    components: {
        Card,
    },
    setup() {

    }
};

</script>

<style scoped>

.container {
    display: flex;
    /* justify-content: flex-start; */
    height: 98%;
    width: 100%;
    background: linear-gradient(to right,
            #ffeda7 0%,
            #ffe88d 20%,
            #ffda56 40%,
            #ffd53b 60%,
            #ffc70f 80%,
            #ff9900 97%,
            #ff7300 100%);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffcc00;

    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;

    z-index: -1;
}


</style>