<template>
  <div id="fileInputDrawer" class="drawer hidden">
      <div class="close-button" @click="hideUploadVortex">🔽</div>
      <div class="file-inputs">
          <div id="upload-area"></div>
      </div>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import Uppy from '@uppy/core'
  import Dashboard from '@uppy/dashboard'
  import XHRUpload from '@uppy/xhr-upload'

  export default {
    name: 'upload-vortex',
    setup(props, { emit, expose }) {
      const uppy = ref(null);
      const additionalFiles = ref([]);
      const addsuccessFilesURL = ref({});

      onMounted(() => {
        uppy.value = new Uppy({
          autoProceed: false,
          restrictions: {
              maxFileSize: null,
              maxNumberOfFiles: null,
              allowedFileTypes: null,
          },
        });

        uppy.value.use(Dashboard, {
          inline: true,
          target: '#upload-area',
          width: '100%',
          showProgressDetails: true,
          showRemoveButtonAfterComplete: true,
        })

        uppy.value.use(XHRUpload, {
          endpoint: 'https://rcodeanalysis.cn/api/file/upload/',
          fieldName: 'file_upload',
          formData: true,
          allowedMetaFields: ['session_id', 'username']
        })

        uppy.value.on('file-added', (file) =>  {
          additionalFiles.value.push(file);
        })

        uppy.value.on('file-removed', (file) =>  {
          additionalFiles.value = additionalFiles.value.filter((f) => f.id !== file.id);
          console.log("file-removed!!!!!!1");
        })

        uppy.value.on('upload-success', (file, response) => {
          addsuccessFilesURL.value[file.name] = response.body.file_url;
          console.log("文件上传成功， addsuccessFilesURL.value[ ", file.name, " ] = ", addsuccessFilesURL.value[file.name])
        })

        uppy.value.on('upload-error', (file, error, response) => {
          console.log('upload error, error = ', error, 'response = ', response);
        })

        uppy.value.on('complete', (result) => {
          console.log('上传完成，成功上传文件数：', result.successful.length);
          console.log('失败的文件数：', result.failed.length);
          emit('hideUploadProgress');
        });

        
      })

      const hideUploadVortex = () => {
        emit('hideUploadVortex');
      }

      // 清理缓存函数，这里主要清理是的Uppy上传的文件缓存。
      const cChache = () => {
        additionalFiles.value = [];
        addsuccessFilesURL.value = {};
      }

      expose({
        uppy,
        additionalFiles,
        addsuccessFilesURL,
        cChache,
      });

      return {
        uppy,
        hideUploadVortex,
      }
    }
  };

</script>

<style scoped>
.drawer {
    position: absolute;
    bottom: -20px;
    width: 90%;
    height: 70%;
    margin-bottom: 1em;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    overflow-y: auto;
    left: 50%;
    transform: translate(-50%, 100%);
    transition: transform 0.5s ease-in-out;
    z-index: 100;
}

.drawer.visible {
    transform: translate(-50%, 0);
    z-index: 9999;
}


.drawer-toggle-button {
    position: absolute;
    left: 2.5%;
    bottom: 7px;
    transform: translate(-50%, -50%);
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    font-weight: 1000;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); */
    transition: background-color 0.3s;
}

.close-button {
    z-index: 10;
    position: sticky;
    top: 0;
    left: 50%;
    width: 30px;
    margin: 0;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 25px;
    font-weight: bold;
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
    user-select: none;
}

.drawer.slide-down {
    animation: slideDown 0.5s forwards;
    /* 动画名称，持续时间和行为 */
}

@keyframes slideDown {
    0% {
        transform: translate(-50%, 0);
    }

    100% {
        transform: translate(-50%, 100%);
    }
}

.drawer.slide-down {
    animation: slideDown 0.5s forwards;
}


</style>


<style>
.uppy-u-reset.uppy-c-btn.uppy-StatusBar-actionBtn.uppy-StatusBar-actionBtn--upload.uppy-c-btn-primary {
  display: none;
}
</style>