<template>
  <Message class="primevue-Message" severity="error" :sticky="true" :life="3000" :style="{ top: props.index * 80 + 'px' }">
    {{ props.messageInfo }}
  </Message>
</template>

<script>

import Message from 'primevue/message';

export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    messageInfo: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      props,
    }
  },
  components: {
    Message
  },
};
</script>

<style scoped>
.primevue-Message {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}
</style>
