<template>
  <div class="header">
    <h1 class="title">Rcode Analysis</h1>
  </div>
</template>

<script>

export default {
    name: 'TitalInfoVue',
};
</script>

<style scoped>

.header {
    position: absolute;
    margin: 0;
    width: 85%;
    
    height: 50px;
    right: 0;
    border: none;
}

.title {
    position: absolute;
    top: -10px;
    left: 10%;
    font-size: 1.7em;
    color: #333;
    margin-left: 1em;
    user-select: none;
}

.beta-test-button {
    right: 10%;
    top: 0;
    font-size: 1.1em;
    position: absolute;
    user-select: none;
    background-color: indianred;
    color: white;
    font-weight: bold;
    width: 110px;
    height: 80%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.beta-test-button:hover {
    background-color: #ff4d4d;
}


/* 添加一个动画效果 */

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.header {
    animation: 1s ease-out 0s 1 slideInFromLeft;
}


</style>