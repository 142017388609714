<template>
    <!-- 监听to_display事件在SessionBar子组件中 -->
    <template v-if="!isPhone">
        <SessionBar v-if="!isPhone" :isPhone="isPhone" ref="SessionBarREF" @to_display_history_messages="to_display_history_messages" @clear_dymatic_messages="clear_dymatic_messages"/>   
    </template>
    <template v-else>
        <Sidebar v-model:visible="visible" style="width: 65%;" @dblclick="handleDoubleClick">
            <template #container>
                <SessionBar ref="SessionBarREF" @to_display_history_messages="to_display_history_messages" @clear_dymatic_messages="clear_dymatic_messages"/>   
            </template>
        </Sidebar>
        <Button @click="visible = true" style="position: absolute; padding: 0; z-index: 100; width: 25px; height: 30px; background-color: transparent; border: none; margin: 5px;">
            <svg style="color: black;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8ZM3 16C3 15.4477 3.44772 15 4 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H4C3.44772 17 3 16.5523 3 16Z" fill="currentColor"></path></svg>
        </Button>
    </template>

    <TitalInfoVue />
    <DisplayArea ref="DisplayAreaREF" :history_messages="history_display_messages" @get_session_list="get_session_list"
                                        @showUploadProgress="showUploadProgress" @hideUploadProgress="hideUploadProgress"
    ></DisplayArea>
    <div v-if="isUploading" class="overlay"></div>
</template> 

<script>

import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import SessionBar from '@/components/SessionBar.vue';
import DisplayArea from '@/components/DisplayArea.vue';
import TitalInfoVue from '@/components/TitalInfo.vue';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';

export default {
    name: 'home-view',
    components: {
        SessionBar,
        DisplayArea,
        TitalInfoVue,
        Sidebar,Button,
    },
    setup() {
        const DisplayAreaREF = ref(null);
        const SessionBarREF = ref(null);
        const isUploading = ref(false);
        const store = useStore();
        const isPhone = ref(false);
        const visible = ref(false);
        const history_display_messages = ref([])

        const clear_dymatic_messages = () => {
            if (DisplayAreaREF.value) {
                DisplayAreaREF.value.clear_dymatic_messages();
            }
        }
        
        const get_session_list = () => {
            if (SessionBarREF.value) {
                SessionBarREF.value.getSessions();
            }
        }

        const showUploadProgress = () => {
            isUploading.value = true;
        }

        const hideUploadProgress = () => {
            isUploading.value = false;
        }

        const windowResize = () => {
            if (window.innerWidth < 768) {
                isPhone.value = true;
            } else {
                isPhone.value = false;
            }
        }

        const handleDoubleClick = (event) => {
            event.preventDefault();
            return false;
        }

        const to_display_history_messages = (messages) => {
            history_display_messages.value = messages;
            if (isPhone.value && visible.value && messages.length > 0) {
                visible.value = false;
            }
        }   

        onMounted(() => {
            window.addEventListener('resize', windowResize);

            if (window.innerWidth < 768) {
                isPhone.value = true;
            } else {
                isPhone.value = false;
            }
        })

        return {
            DisplayAreaREF,
            SessionBarREF,
            isUploading,
            visible,isPhone,
            store,
            history_display_messages,
            to_display_history_messages,
            clear_dymatic_messages,
            get_session_list,
            showUploadProgress,hideUploadProgress,
            handleDoubleClick,
        }
    }
}

</script>

<style> 

.overlay {
    position: absolute; /* 或absolute，取决于你的布局需求 */
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    background-color: rgba(128, 128, 128, 0.3); /* 半透明的灰色 */
    z-index: 8000;
}


@media screen and (max-width: 768px) {
    .session-bar {
        position: absolute !important   ;
        width: 100% !important;
    }

    html, body {
        margin: 0;
        -webkit-touch-callout: none; /* 禁止长按链接与图片弹出菜单 */
        -webkit-tap-highlight-color: transparent; /* 点击高亮透明 */
        -webkit-text-size-adjust: 100%;
        -webkit-user-drag: none; /* 禁止图片拖拽 */
    }

    element {
        /* 替换element为您要绑定双击事件的元素选择器 */
        -webkit-touch-callout: default; /* 开启长按链接与图片弹出菜单 */
    }

    .header {
        left: 0;
        margin: 0;
        top: -10px;
    }

    .display {
        width: 95% !important;
    }

    .postTextWrapper {
        width: 90% !important;
        margin-top: 15px !important;
    }

    .postText {
        position: absolute; 
        margin-left: 20px !important;
        margin-right: 15px !important;
        -webkit-appearance: none !important;
        -moz-appearance: none;
        appearance: none;
        width: 83% !important;
        font-size: 14px !important;
    }

    .drawer-toggle-button {
        /* display: none !important; */
        margin: 0;
        padding: 0;

        left: 5px !important;
        bottom: 12px !important;
    }
    .confirmPost {
        /* display: none; */
        height: 25px !important;
        width: 25px !important;
        right: 5px !important;
        font-size: 18px !important;
        line-height: 25px !important;
        border: none;
        border-radius: 50% !important;

        bottom: 12px !important;
    }
    .drawer{
        display: none;
    }

    #app {
        padding-bottom: env(safe-area-inset-bottom) !important;
    }

    .container {
        height: 100% !important;
    }

    .message-avatar {
        position: absolute;
        top: 12px;
        width: 30px;
        height: 30px;
    }

    .message-name {
        position: relative;
        font-size: 16px;
        left: 30px;
    }

    .message-text {
        position: relative;
        font-size: 15px;
        left: 10px;
        top: 20px
    }

    .message-image {
        position: relative;
        top: 5px;
        left: 10px;
        margin-bottom: 0px;
        max-height: 90%;
        max-width: 90%;
    }

    .message-per {
        margin-bottom: 20px;
    }

    .message-code-text {
        font-size: 15px;
    }

    .FileDisplayPanel {
        position: relative;
        top: 20px !important;
    }

    .DataParentElement {
        width: 85% !important;
        left: 50% !important;
        transform: translateX(-50%);
    }

    .message-text-error {
        position: relative;
        top: 15px;
        left: 10px;
    }

    .retry-button {
        position: relative;
        left: 50% !important;
        transform: translateX(-50%) !important;
    }
}

</style>
