<!-- 该组件必须配合display组件和session-bar组件一起使用，该组件被挂在到display中。 -->

<template>
    <div class="postTextWrapper" ref="postTextWrapper">
      <button id="toggleButton" class="drawer-toggle-button" @click="showUploadVortex">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z" fill="currentColor"></path></svg>
      </button>
      <textarea placeholder="Message Analysis..." class="postText" ref="postTextREF" @input="updateSize"></textarea>
      <div v-if="phoneAndInputShowSendBTN" class="button confirmPost" ref="confirmREF" :class="{ disabled: isDisabledButton }" @click="isDisabledButton ? null :requestDialogue">&uarr;</div>
      <!-- <div v-else-if="!phoneAndInputShowSendBTN" class="button voiceModePost" ref="voiceModeREF" @click="showVoiceDialogue"><svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" style="height: 24px; width: 24px;"><path d="M880 928a120 120 0 0 1-120-120V696a119.952 119.952 0 0 1 88-115.552V496a336 336 0 0 0-672 0v84.448A119.952 119.952 0 0 1 264 696v112a120 120 0 0 1-240 0V696a119.952 119.952 0 0 1 88-115.552V496a400 400 0 0 1 800 0v84.448A119.952 119.952 0 0 1 1000 696v112a120 120 0 0 1-120 120zM88 696v112a56 56 0 0 0 112 0V696a56 56 0 0 0-112 0z m848 0a56 56 0 0 0-112 0v112a56 56 0 0 0 112 0V696zM432 600a32 32 0 0 1 32 32v240a32 32 0 0 1-64 0V632a32 32 0 0 1 32-32z m160 80a32 32 0 0 1 32 32v80a32 32 0 0 1-64 0V712a32 32 0 0 1 32-32z" fill="#000000" stroke="#000000" stroke-width="30"></path></svg></div> -->
    </div>
    <UploadVortex ref="UploadVortexREF" @hideUploadVortex="hideUploadVortex" @hideUploadProgress="hideUploadProgress"
      :class="{ visible: isUploadVortexVisible }"
    />
</template>

<script>
  import { useStore } from 'vuex';
  import { ref, onMounted, onUnmounted } from 'vue';
  import router from '@/router';
  import cookies from 'js-cookie';

  import UploadVortex from '@/components/UploadVortex.vue';
  import axios from 'axios';
  import RecordRTC from 'recordrtc';

  export default {
    name: 'dialogueClient',
    props: {
    },
    setup(props, { emit, expose }) {
      const store = useStore()
      const username = cookies.get('username');
      const isDisabledButton = ref(false);
      const postTextWrapper = ref(null);
      const maxHeight = 300;
      const api_reply = ref('');
      const isUploadVortexVisible = ref(false);
      const iscChache = ref(false);  // 是否清理缓存
      const iscShows = ref(false);  // 是否清理显示提示
      const phoneAndInputShowSendBTN = ref(true);

      const postTextREF = ref(null);
      const UploadVortexREF = ref(null);
      const socket = ref(null);

      // 音频
      const recorder = ref(null);
      const audioStream = ref(null);
      const audioQueue = ref([]); // 音频队列
      const audioContext = ref(null);
      const isPlaying = ref(false);
      const isStopPlaying = ref(false);
      const isInitAudioContext = ref(false);
      // end

      if (username !== undefined) {
          store.commit('updateUserLoginState', {username});
      }
      if (store.state.user.is_login === false) {
        router.push({name: 'login'});
        return;
      }

      const sendMessage = (message) => {
        if (socket.value && socket.value.readyState === WebSocket.OPEN) {
          try {
            socket.value.send(JSON.stringify(message));
          } catch (error) {
            console.error('发送失败:', error);
            alert('发送失败:' + error);
          }
        } else {
          console.error('WebSocket is not connected.');
          alert('连接出错了，将自动刷新本页面。');
          location.reload();
        }
      };

      const requestDialogue = async (is_retry=false) => {
        if (!postTextREF.value || !postTextWrapper.value) {
          console.error('postText or postTextWrapper is null.');
          return;
        }

        if (socket.value.readyState !== WebSocket.OPEN) {
          console.error('WebSocket is not connected.');
          alert('连接出错了，将自动刷新本页面。');
          location.reload();
          return;
        }

        if (is_retry) {
          emit('showLoadingIndicator');
          let sendbuf = {
            'action': 'postAPIReply',
            'username': store.state.user.username,
            'session_id': store.state.user.cur_session_id,
            'user_input': '',
            'user_uploads':'',
            'reGenerate': true,
          };
          sendMessage(sendbuf);
          return;
        }

        let text = postTextREF.value.value.trim();
        if (text === '' || text.length === 0){
          emit('pushInfoMessage', "请输入请求内容！");
          return;
        }
        disableButton();

        if (store.state.user.cur_session_id === -1) {
          try {
            const response = await axios.post('https://rcodeanalysis.cn/api/create_session/', {
              username: store.state.user.username,
            });
            const sessionId = response.data.session_id;
            store.commit('updateSelectSessionId', {session_id: sessionId});
            // 在这里处理sessionId
          } catch (error) {
            console.error('请求失败:', error);
            alert('请求失败:' + error);
            // 在这里处理错误情况
          }
        }

        // 对上传的文件进行处理
        const additionalFiles = UploadVortexREF.value.additionalFiles;
        const addsuccessFilesURL = UploadVortexREF.value.addsuccessFilesURL;
        if (additionalFiles.length > 0) {
          showUploadProgress();

          // 为每一个file添加字段
          additionalFiles.forEach(f => {
            UploadVortexREF.value.uppy.setFileMeta(f.id, {
              session_id: store.state.user.cur_session_id,
              username: store.state.user.username,
            });
          });
          try {
            await UploadVortexREF.value.uppy.upload();
          } catch (error) {
            console.error('上传失败:', error);
            alert('上传失败:' + error);
            // 在这里处理错误情况
          }
        }
        // end 
        const user_uploads = [];
        additionalFiles.forEach(f => {
          user_uploads.push({'file_name': f.name, 'URL': addsuccessFilesURL[f.name]});
        });

        hideUploadProgress();

        let sendbuf = {
          'action': 'postAPIReply',
          'username': store.state.user.username,
          'session_id': store.state.user.cur_session_id,
          'user_input': text,
          'user_uploads':user_uploads,
        };
        sendMessage(sendbuf);
        postTextREF.value.value = '';
        updateSize();
        iscChache.value = true;
        iscShows.value = true;
        
        emit('to_display_dynamic_messages', 'user', text, user_uploads);
        emit('showLoadingIndicator');
      };

      // 语音相关处理
      const showVoiceDialogue = () => {
        emit('showVoiceDialogue');
        if (!isInitAudioContext.value) {
          initAudioContext();
          isInitAudioContext.value = true;
        }
      };

      const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary); // 编码成 Base64 字符串
      }

      const startRecording = async() => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          audioStream.value = stream;

          // 使用StereoAudioRecorder并尝试设置为WAV格式
          recorder.value = new RecordRTC(audioStream.value, {
            type: 'audio',
            mimeType: 'audio/wav',  // 指定MIME类型为WAV
            recorderType: RecordRTC.StereoAudioRecorder,  // 使用StereoAudioRecorder
            numberOfAudioChannels: 1, // 单声道，如果需要可以设置为2
            desiredSampRate: 16000, // 示例采样率，根据需要调整
          });

          emit('pushInfoMessage', "开始录音...");
          recorder.value.startRecording();
        } catch (error) {
          console.error('录音启动失败:', error)
          emit('pushInfoMessage', "录音启动失败:" + error);
        }
      }

      const stopRecording = async () => {
        recorder.value.stopRecording(async () => {
          try {
            const blob = recorder.value.getBlob();
            requestAudioDialogue(blob);
          } catch (error) {
            console.error('录音失败:', error);
          }

          // 停止媒体流
          audioStream.value.getTracks().forEach(track => track.stop());
        });
        console.log('录音结束.');
        emit('pushInfoMessage', "录音结束.");
      };

      const requestAudioDialogue = async (blob) => {
        if (socket.value.readyState !== WebSocket.OPEN) {
          console.error('WebSocket is not connected.');
          alert('连接出错了，将自动刷新本页面。');
          location.reload();
          return;
        }

        if (store.state.user.cur_session_id === -1) {
          try {
            const response = await axios.post('https://rcodeanalysis.cn/api/create_session/', {
              username: store.state.user.username,
            });
            const sessionId = response.data.session_id;
            store.commit('updateSelectSessionId', {session_id: sessionId});
            // 在这里处理sessionId
          } catch (error) {
            console.error('请求失败:', error);
            alert('请求失败:' + error);
            // 在这里处理错误情况
          }
        }

        const arrayBuffer = await blob.arrayBuffer(); // 将 Blob 转换为 ArrayBuffer
        const base64Audio = arrayBufferToBase64(arrayBuffer); // 转换为 Base64

        let sendbuf = {
          action: 'postAudioReply',
          username: store.state.user.username,
          session_id: store.state.user.cur_session_id,
          user_audio: base64Audio, // 发送 Base64 编码的字符串
        };

        console.log('录音结束，发送音频数据:', sendbuf);
        sendMessage(sendbuf);
      };

      // 该函数只会被调用一次，在showVoiceDialogue中。当不再使用音频时，可以使用audioContext.close()关闭音频上下文。
      const initAudioContext = () => {
        audioContext.value = new AudioContext();
        const buffer = audioContext.value.createBuffer(1, 1, 22050); // 创建一个极短的缓冲区
        const source = audioContext.value.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.value.destination);
        source.start(0);
      };

      const playAudioWithAudioContext = (URL) => {
        if (audioContext.value) {
          // 使用Fetch API获取音频文件
          fetch(URL)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => audioContext.value.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
                const source = audioContext.value.createBufferSource();
                source.buffer = audioBuffer;
                source.connect(audioContext.value.destination);
                source.start(0); // 立即播放

                source.onended = () => {
                  emit('pushInfoMessage', "音频播放结束.");
                  checkAndStartAudioQueue();
                };
            })
            .catch(e => console.error("音频播放失败", e));
          }
      }

      const checkAndStartAudioQueue = () => {
        if (audioQueue.value.length > 0) {
          isPlaying.value = true;

          const audioUrl = audioQueue.value.shift();
          playAudioWithAudioContext(audioUrl);
        } 
        // else if (!isStopPlaying.value) {
        //   // 循环100ms检查一次
        //   setTimeout(checkAndStartAudioQueue, 100);
        // }
        else {
          setTimeout(checkAndStartAudioQueue, 100);
        }
      };
     
      // end

      const keyupHandler = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();

          if (isDisabledButton.value) {
            return;
          }
          requestDialogue();
        } else if (e.key === 'Tab') {
          e.preventDefault();
        }
      };

      // 示例：禁用按钮和输入
      const disableButton = () => {
        isDisabledButton.value = true;
      };

      const enableButton = () => {
        isDisabledButton.value = false;
      };

      const updateSendorVoiceIcon = () => {
        // 如果是手机
        if (window.innerWidth < 768) {
          // 判断当前输入框的内容是否为空
          if (postTextREF.value.value.trim() === '') {
            phoneAndInputShowSendBTN.value = false;
          } else {
            phoneAndInputShowSendBTN.value = true
          }
        } else {
          phoneAndInputShowSendBTN.value = true;
        }
      };
      

      const updateSize = () => {
        if (!postTextREF.value || !postTextWrapper.value) return;
        postTextREF.value.style.height = 'calc(1.5em + 20px)';
        postTextWrapper.value.style.height = '30px';

        let scrollHeight = postTextREF.value.scrollHeight;
        let newHeight = scrollHeight;
        if (newHeight > maxHeight) {
          postTextREF.value.style.height = maxHeight + 'px';
          postTextREF.value.style.overflowY = 'auto';
          postTextWrapper.value.style.height = maxHeight + 'px';
        } else {
          postTextREF.value.style.height = newHeight + 'px';
          postTextREF.value.style.overflowY = 'hidden';
          postTextWrapper.value.style.height = newHeight + 'px';
        }

        updateSendorVoiceIcon();
      };

      const showUploadVortex = () => {
        isUploadVortexVisible.value = true;
      };

      const hideUploadVortex = () => {
        isUploadVortexVisible.value = false; 
      };

      const showUploadProgress = () => {
        showUploadVortex();
        emit('showUploadProgress');
      };

      const hideUploadProgress = () => {
        hideUploadVortex();
        emit('hideUploadProgress');
      };

      const retryPost = () => {
        // 重新发送请求
        requestDialogue(true);
      };

      const connectWebSocket = () => {
        socket.value = new WebSocket('wss://rcodeanalysis.cn/ws/js_server/');
        socket.value.onopen = function(event) {
          console.log('websocket connected, event: ', event);
          store.commit('updateSelectSessionId', {session_id: -1});
          store.commit('updateSelectProjectSessionId', {project_session_id: -1});
          store.commit('updateWebSocketConnectingState', {isWebSocketConnecting : true});
        };

        socket.value.onmessage = function(event) {
          const message_data = JSON.parse(event.data);
          const action = message_data.action;
          if (message_data.error && message_data.error === "VPS Client is not connected.") {
            console.error('错误：', message_data.error);
            alert('错误：' + message_data.error);
            this.close();
            return;
          }

          if (store.state.user.cur_session_id == -1) {
            console.log("新session被服务器创建，id为" + message_data.session_id + "。")
            store.commit('updateSelectSessionId', {session_id: message_data.session_id});
          }

          switch (action) {
            case "new_session_name":
              console.log("新会话名称被创建，名称为" + message_data.new_session_name + "。");
              emit('get_session_list');
              return;

            case "func_called":
              emit('showToolIndicator', message_data.func_cname);
              iscShows.value = true;
              return

            case "APIReplyMessage":
              try {
                const role = message_data.role;
                if (role === 'analysis') {
                  if (iscChache.value) {
                    UploadVortexREF.value.cChache();
                    let sendbuf = {
                      'action': 'postCachePurge',
                      'username': store.state.user.username,
                      'session_id': store.state.user.cur_session_id,  
                    }
                    // 将当前的uppy中的文件清空
                    const files = UploadVortexREF.value.uppy.getFiles();
                    if (files.length > 0) {
                      files.forEach(f => {
                        UploadVortexREF.value.uppy.removeFile(f.id);
                      });
                    }
                    sendMessage(sendbuf);
                    iscChache.value = false;
                  }

                  if (iscShows.value) {
                    emit('hideLoadingIndicator');
                    emit('hideToolIndicator');
                    iscShows.value = false;
                  }
                  // end
                  
                  // 更新父组件的数据
                  emit('to_display_dynamic_messages', 'Analysis', message_data.text, null);
                  api_reply.value += message_data.text;

                  if (message_data.text === 'END') {
                    emit('get_session_list');
                    enableButton();

                    // 这是管理播放音频状态的逻辑。
                    isStopPlaying.value = true;
                    return;
                  }
                } else if (role === 'user') {
                  // 这里通知display，后续更新。
                  emit('to_display_dynamic_messages', 'user', message_data.text, []);
                  return;
                }
              } catch (error) {
                console.error('错误处理APIReplyMessage:', error);
              }
              return;

            case "audioChunk": 
              try {
                const audioChunkBase64 = message_data.audioChunk;
                const audioBlob = new Blob([Uint8Array.from(atob(audioChunkBase64), c => c.charCodeAt(0))], { type: 'audio/mpeg' });
                const audioUrl = URL.createObjectURL(audioBlob);
                audioQueue.value.push(audioUrl);
                isStopPlaying.value = false;

                console.log("加入音频队列。");

                if (!isPlaying.value) {
                  checkAndStartAudioQueue();
                }
              } catch (error) { 
                console.error('错误处理audioChunk:', error);
              }
              return;
            case "":
              return;
            
          }
        };
      };

      expose({
        retryPost,
        startRecording, stopRecording,
      });

      onMounted(() => {
        connectWebSocket();

        updateSize();
        window.addEventListener('keydown', keyupHandler);
        // 添加输入框的监听事件，判断如果是手机端的话，就添加blur事件，这是对于虚拟键盘回收的处理
        if (window.innerWidth < 768) {
          postTextREF.value.addEventListener('blur', ()=>{
            setTimeout(function() {
              let scrollHeight = postTextREF.value.scrollHeight;
              window.scrollTo(0, Math.max(scrollHeight - 1, 0));
            }, 100);
          });
        }

        // 添加focus事件，判断websocket是否连接，如果没有连接，就重新连接
        window.addEventListener('focus', ()=>{
          if (socket.value.readyState !== WebSocket.OPEN) {
            connectWebSocket();
          }
        });

        window.addEventListener('resize', ()=>{
          updateSendorVoiceIcon();
        });

        // 监听websocket的断开事件，如果断开了，就重新连接
        socket.value.onclose = function(event) {
          console.log('websocket closed, event: ', event);
          connectWebSocket();
        };

      });

      onUnmounted(() => {
        window.removeEventListener('keydown', keyupHandler);
      });
      

      return {
        socket,
        postTextREF,postTextWrapper,UploadVortexREF,
        isDisabledButton,phoneAndInputShowSendBTN,
        isUploadVortexVisible,
        updateSize,
        requestDialogue,showVoiceDialogue,
        sendMessage,
        disableButton,enableButton,
        showUploadVortex,hideUploadVortex,showUploadProgress,hideUploadProgress,
        connectWebSocket,
      }
    },
    components: {
      UploadVortex,
    },
  }

</script>

<style scoped>
.input-container {
    width: 85%;
    flex-grow: 0.8; 
    flex-shrink: 1; 
}


.postTextWrapper {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 83%;
    flex-grow: 0.01; 
    flex-shrink: 1; 
    display: inline-block;
    vertical-align: middle;
    background-color: white;
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-width: 0;
    bottom: 0px;

    position: relative;
}

.postText {
    position: absolute;
    z-index: 0;
    width: 90%;
    padding: 0.5em;
    font-size: 1em;
    border: none;
    resize: none;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;

    margin: 0;
    border-width: 0;
    outline: none;
    box-sizing: border-box;

    height: calc(1.5em + 20px);
    line-height: 1.5em;
    padding: 10px;
    font-family: 'Arial', sans-serif;
    top: 50%;
    transform: translateY(-50%);
    min-height: calc(1.5em + 20px);
    background-color: transparent;
    margin-left: 35px;
    margin-right: 35px;
}

.confirmPost {
    position: absolute;
    right: 10px;
    bottom: 11px;
    width: 30px;
    height: 30px;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    font-weight: 1000;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s;
    user-select: none;
}

.voiceModePost {
  position: absolute;
  right: 3px;
  bottom: 6px;
  width: 30px;
  height: 30px;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  font-weight: 1000;
  background-color: transparent;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  transition: background-color 0.3s;
  user-select: none;
}

.drawer-toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute; 

    left: 10px;
    bottom: 13px;

    background-color: transparent;

    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    user-select: none ;
}

</style>