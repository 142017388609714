
<template>
    <div class="card">
        <div class="username-div" style="margin-bottom: 1em;">
            <label class="w-6rem" style="margin-right: 1em;">Username</label>
            <InputText v-model="username" id="username" type="text" class="w-12rem" />
        </div>
        <div class="password-div" style="margin-bottom: 1em;">
            <label class="w-6rem" style="margin-right: 1em;">Password</label>
            <InputText v-model="password" id="password" type="password" class="w-12rem" />
        </div>
        <Button v-on:click="login" label="Login" icon="pi pi-user" class="loginBtn"></Button>
    </div>

</template>

<script>

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name:"login-view",
    components:{
        InputText,
        Button,
    },
    setup() {
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let error_message = ref('');

        const login = () => {
            error_message.value = '';
            store.dispatch("login", {
                username: username.value,
                password: password.value,
                success() {
                },
                error() {
                    error_message.value = "用户名或密码错误"
                }
            });
        };

        return  {
            username,
            password,
            login,
        }
    }
}
</script>

<style scoped>

.card {
    background-color: white;
    width: 70%;
    height: 40%;
    margin: auto;   
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

}

.username-div {
    margin-bottom: 1rem;
}

.password-div {
    margin-bottom: 1rem;
}   

@media screen and (max-width: 768px) {  
    .card {
        width: 90%;
        height: 40%;
    }

    html, body {
        margin: 0;
        -webkit-touch-callout: none; /* 禁止长按链接与图片弹出菜单 */
        -webkit-tap-highlight-color: transparent; /* 点击高亮透明 */
        -webkit-user-drag: none; /* 禁止图片拖拽 */
    }

    element {
        /* 替换element为您要绑定双击事件的元素选择器 */
        -webkit-touch-callout: default; /* 开启长按链接与图片弹出菜单 */
    }

    .loginBtn {
        margin-top: 30px;
    }

    .p-inputtext {
        width: 100%;
    }

}

</style>


<style>
@media screen and (max-width: 768px) {
    .loginBtn {
        height: 50px;
        min-height: 50px;
        margin-top: 10px !important;
    }
}
</style>
